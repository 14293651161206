import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.darker",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "20px 60px", lg: "20px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", color: "white" }}>
            <img
              style={{ height: "50px", cursor: "pointer", marginRight: "5px" }}
              src={Images.codeWhite}
              alt="Code Challenge Championship Logo"
              onClick={() => navigate("/")}
            />
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                color: "white",
                marginLeft: "5px",
              }}
            >
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/privacy")}
              >
                Privacy
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/terms")}
              >
                Terms
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/refund")}
              >
                Refund
              </Typography>
            </Box>
          </Box>
          <div
            style={{ display: "flex", alignItems: "center", height: "60px" }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "secondary.main",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Stay in touch
            </Typography>
            <a
              target="_blank"
              href="https://www.facebook.com/CodeChallengeChampionship"
              className="FooterIconBox"
            >
              <FacebookIcon className="SocialFooterIcon" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/codechallengechampionship/"
              className="FooterIconBox"
            >
              <InstagramIcon className="SocialFooterIcon" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/code-challenge-championship/"
              className="FooterIconBox"
            >
              <LinkedInIcon className="SocialFooterIcon" />
            </a>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=%2B201500333864&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
              className="FooterIconBox"
            >
              <WhatsAppIcon className="SocialFooterIcon" />
            </a>
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            color: "white",
            justifyContent: "Center",
            marginTop: "10px",
            marginBottom: "-15px",
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/privacy")}
          >
            Privacy
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/terms")}
          >
            Terms
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/refund")}
          >
            Refund
          </Typography>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #fff",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "5px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
            }}
          >
            CodeChallengeChampionship© All Rights reserved.
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Powered by{" "}
            <img
              src={Images.pulsonic}
              alt="pulsonic"
              style={{ height: "20px", marginLeft: "10px" }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
