import { AcademiesLogo, Images } from "./Images";

export const categoriesData = [
  {
    image: Images.code1,
    title: "KidCoder",
    link: "/competition/kidcoder",
  },
  {
    image: Images.code2,
    title: "Web Challenge",
    link: "/competition/web",
  },
  {
    image: Images.code3,
    title: "Python Challenge",
    link: "/competition/python",
  },
  {
    image: Images.code4,
    title: "AI Challenge",
    link: "/competition/ai",
  },
];

export const reviews = [
  {
    review: (
      <span>
        There was a nice challenge and a real evaluation of the student’s
        understanding. We are happy with the experience and God willing, we will
        be with you every year.
      </span>
    ),
  },
  {
    review: (
      <span>
        All the particpants were happy with the awards & the educational content
        and evaluation for the children was very good & your keenness to follow
        up and respond.
      </span>
    ),
  },
  {
    review: (
      <span>
        The organizers of the competition itself are very polite and
        sophisticated in their dealings, and the idea of ​​the competition
        itself is beautiful and distinctive.
      </span>
    ),
  },
  {
    review: (
      <span>
        Benefiting children in developing their problem solving skills
      </span>
    ),
  },
];

export const arrayOfAcademies = [
  { name: "4My-Robot" },
  { name: "AA Robotics" },
  { name: "AAST teams" },
  { name: "ACA Robotics" },
  { name: "AI Academy" },
  { name: "Al Farouk Academy" },
  { name: "Al Safa Language School" },
  { name: "Almobbtakron" },
  { name: "Alpha Robot Academy" },
  { name: "American languages schools Shebin Elkoum" },
  { name: "Artifinity" },
  { name: "ASME Assuit" },
  { name: "Asdaa" },
  { name: "BareeQ Academy" },
  { name: "begining Academy" },
  { name: "Big Hero Academy" },
  { name: "Bricks Builder" },
  { name: "Child Home Academy" },
  { name: "CodeCraft" },
  { name: "CodeWave" },
  { name: "Creative Generation Academy" },
  { name: "Discovery Academy (Monofia)" },
  { name: "Discovery Academy (TAGAMOU3)" },
  { name: "ETS Academy" },
  { name: "Future Makers Academy" },
  { name: "FutureMinds Academy" },
  { name: "Futuristic Science Organization" },
  { name: "GENIUS CODER" },
  { name: "Genius Academy" },
  { name: "Genius Minds" },
  { name: "HB Tech Academy" },
  { name: "Helwan University Faculty of Engineering" },
  { name: "I Can Code Academy" },
  { name: "I.C academy" },
  { name: "Ibdaa Academy" },
  { name: "Innova STEM Education" },
  { name: "Innovation-hub" },
  { name: "IRON Teams" },
  { name: "IVY STEM International School" },
  { name: "Jupiter Academy" },
  { name: "Little Engineer Academy" },
  { name: "Little Engineer Center" },
  { name: "M&P Robotics" },
  { name: "Maven International School" },
  { name: "Mawaheb (El-Aasher)" },
  { name: "Mawaheb Academy (Maadi)" },
  { name: "Mawaheb Academy (Nasr city)" },
  { name: "Mawaheb Academy (Suez)" },
  { name: "MAY UNIVERSITY IN CAIRO" },
  { name: "Mega Robotics Academy" },
  { name: "Metanoia planet Academy" },
  { name: "MMS Academy" },
  { name: "Map Language School" },
  { name: "Mind Shine" },
  { name: "Noor Language School" },
  { name: "Robot Academy" },
  { name: "ROBOTIC-FOR-KIDS" },
  { name: "RoboDizer Academy" },
  { name: "Roboharvest Academy" },
  { name: "Robokids Academy" },
  { name: "Robotica Academy" },
  { name: "Robotix Academy" },
  { name: "Robotopia Academy (Fayoum)" },
  { name: "STS Academy" },
  { name: "STEM Zone Academy" },
  { name: "Sciences Academy" },
  { name: "Techware Academy" },
  { name: "Techno Square Academy" },
  { name: "Techno Titans Academy" },
  { name: "Technospace team" },
  { name: "Technology leaders (6 October)" },
  { name: "Technology leaders (Zayed)" },
  { name: "Treasures Academy Ganakles" },
  { name: "Treasures Academy sidi gaber" },
  { name: "Utopia Academy" },
  { name: "VBO TECH" },
  { name: "Willy Robotics Academy" },
  { name: "Wissen Academy" },
  { name: "X_robot Academy" },
  { name: "ZHUB Academy" },
];
