import React from "react";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import CountDown from "../../Components/organisms/Home/CountDown/CountDown";
import Highlights from "../../Components/organisms/Home/Highlights/Highlights";
import Numbers from "../../Components/organisms/Home/Numbers/Numbers";
import Ranking from "../../Components/organisms/Home/Ranking/Ranking";
import Reviews from "../../Components/organisms/Home/Reviews/Reviews";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import Number from "../../Components/organisms/Home/Numbers/Number";
import { Images } from "../../helpers/Images";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import Carousel from "react-material-ui-carousel";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TaskIcon from "@mui/icons-material/Task";
import StarsIcon from "@mui/icons-material/Stars";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

// var items = [
//   {
//     name: "Explore Our STEAM Events",
//     description: "See More",
//     image: Images.back2,
//     link: "/accreditation",
//   },
//   {
//     name: "Explore Our STEAM-Based Curricula",
//     description: "See More",
//     image: Images.back2,
//     link: "/accreditation",
//   },
// ];

const HomeTemplate = () => {
  return (
    <>
      <CountDown />
      <Number />
      <CategoriesCarousel />
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#6a0505",
        }}
      >
        <img
          src={Images.cover2}
          style={{ width: "100%", maxHeight: "600px", objectFit: "cover" }}
        />
      </div> */}
      <Ranking />
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "0px 20px 50px",
            sm: "0px 60px 50px",
            lg: "0px 120px 50px",
          },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Have a look at what you could win
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 0px",
            maxWidth: "800px",
            textAlign: "20px",
          }}
        >
          <Grid container>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <MilitaryTechRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize4} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Medals
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <EmojiEventsRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize2} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Trophies
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <LocalAtmIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize5} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Cash Prizes
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <ShoppingBagRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize3} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Welcome Bags
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <CardMembershipRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize1} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Accredited Certificates
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <CardGiftcardIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize6} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Gift Vouchers
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Highlights />
      <LogoCarouse />
      <Reviews />
      <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px 50px",
            sm: "50px 60px 50px",
            lg: "50px 120px 50px",
          },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Why Code Challenge Championship ?
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 50px",
            maxWidth: "1000px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList">
            <li>
              <WorkspacePremiumIcon className="listTick" />
              Endorsed by STEM.org for credibility
            </li>
            <li>
              <EmojiEventsIcon className="listTick" />
              Exciting prizes including cash, medals, and trophies
            </li>
            <li>
              <TaskIcon className="listTick" />
              High-quality missions simulating real-world problems
            </li>
            <li>
              <StarsIcon className="listTick" />
              Real-time evaluation and ranking system
            </li>
            <li>
              <SupervisedUserCircleIcon className="listTick" />
              ToT support to aid organizations in competition preparations
            </li>
            <li>
              <QuizIcon className="listTick" />
              Improving problem solving, and technical skills
            </li>
            <li>
              <Diversity2Icon className="listTick" />
              Fostering teamwork and exclusive networking opportunities
            </li>
          </ul>
        </Box>
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "20px" }}>
          {[
            {
              question: "What's Code Challenge Championship?",
              answer:
                "It's an annual coding and programming competition designed to test their coding skills and creativity.",
            },
            {
              question: "Who can participate in Code Challenge Championship?",
              answer:
                "Students aged from 5 to 24 years are welcomed to particpate in the available tracks.",
            },
            {
              question: "What are the available tracks?",
              answer:
                "In 2025, we have 4 main tracks as follows: (KidCoder Challenge, Web Challenge, Python Challenge, AI Challenge)",
            },
            {
              question: "How the competition look like?",
              answer:
                "There will be 3 challenges for each category with ascending difficulty. Teams have 45 minutes to finish each challenge.",
            },
            {
              question: "What's the team structure for participation?",
              answer:
                "The team should have 1-3 members guided by a coach/teacher.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong>{item.question}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default HomeTemplate;
