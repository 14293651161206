import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery, GalleryArray } from "../../../../helpers/gallery";

const AITemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              Code Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#010101",
                }}
              >
                AI Challenge
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "10px auto",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fJNm6vlMX8SpXNVQWo3RZ5P2m-DJx-PH"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download General Rules (PDF)
                  </Button>
                </a>
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1rFXUhExe-eMwjSTk-31o86gM9scf1wRp"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download AI Rules (PDF)
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fAtqP7FwyN7QBDE_dv_CY6FnVwu9csUX"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download AI Challenge 2024 Scoring Sheet
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "17px",
                    margin: "5px 0",
                    fontWeight: "600",
                    backgroundColor: "#db2422",
                  }}
                  onClick={() => navigate("/firststage")}
                >
                  Register Now
                </Button>
              </Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                AI is the science that concerns about how to simulate the
                human’s intelligence in machines to be able to take actions and
                decisions without being explicitly programmed to do so. The
                power of AI lies in its ability to replicate and even surpass
                human intelligence in certain domains. By harnessing advanced
                algorithms, massive datasets, and computational power, AI
                systems can analyze information, recognize patterns, and make
                decisions with incredible speed and accuracy. So, if you want to
                be one of the future AI engineers that can utilize AI
                efficiently to simplify our lives and solve real-world problems,
                join us this year in AI Challenge!
                <br /> <br /> In AI challenge, there are{" "}
                <strong>three main categories and three main rounds</strong> in
                each category with different challenges. Each participant/team
                should finish the challenge and submit their work in specified
                time !..
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "20px",
                  }}
                >
                  Junior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                10 - 13
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                PictoBlox (Block coding environment)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1XU7sF3S7v554sEiX8tl1N_DmnhHe7o2x"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Junior 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1MsM7pP8NSvf8YV0Cbq-XGJj3LL11dHeA"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Junior 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fF0xsJcsp9_JNGgU3M3mvnZiWbq408L6"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Junior 2024 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1El7qA1A1wsp_10LK1T6fuHPfI2Vs_CwX"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download AI Junior Sample 1
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Senior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                14 - 17
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                PictoBlox (Block/Python coding environment)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1LaIz4A_R2k82er8yUSP0pW6TUvgSVg0W"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Senior 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=16KUFa9e9sLQinWR_-Ud0muf3jFhZF1aW"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Senior 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1oUcL6BN2qVjYWbt1ojIXOPjiCNiy4V8P"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Senior 2024 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1sVr0dtLqmQRlRuLCBDeQeD01p-qaL5Ox"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download AI Senior Sample 1
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Adult Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                18 - 24
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                Python (VS code, and Jupyter notebook)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1CfedXw2ikDYRyXPdFBO0O2LfVasfzPgS"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Adult 2024 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1eRohLre1KIGi_Oh2l6xvrsgyquDkGXto"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Adult 2024 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1LKR-gU98DECLSu_YDBEdpSWzNR1fL3FK"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    AI Adult 2024 Mission 3
                  </Button>
                </a>
                <br />
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              {GalleryArray?.sort((a, b) => 0.5 - Math.random())
                ?.slice(0, 7)
                ?.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <img
                      loading={"lazy"}
                      alt={i}
                      src={item}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Tracks
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "AI Challenge")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AITemplate;
